import React from 'react';
import { Link } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';

import Layout from '../components/Layout'
import SellersSwiper from '../components/Swiper'
import CallToAction from "../components/CallToAction";


import { pageLink, conversionId, googleConversion } from '../utils'

import peopleImg from '../assets/images/people.png'
import billboardCityImg from '../assets/images/billboard_city_412.jpg';
import gridIslandImg from '../assets/images/grid_art_island.jpg';
import gridGalleryImg from '../assets/images/grid_art_gallery.jpg';
import gridBlockImg from '../assets/images/grid_art_block.jpg';
import gridPondImg from '../assets/images/grid_art_pond.jpg';
import gridStreetImg from '../assets/images/grid_art_street.jpg';
import gridPortalImg from '../assets/images/grid_art_portal.jpg';

import * as styles from "./sellers.module.scss"

const sellers = () => {
  return (
    <Layout
      title="Double | Sellers & Creators"
      pageClassName={`page-seller ${styles.pageSeller}`}
    >
      <section id="hero" className={styles.sectionHero}>
        <img src={billboardCityImg} alt="" />
        <div className={styles.container}>
          <div className={styles.contentWrapper}>
            <h1 className={styles.pageTitle}>A World of New Opportunities</h1>
            <p className={styles.content}>
              The Metaverse is a world of opportunity that cannot afford to be missed. By 2030, the Metaverse economy is expected to be worth upwards of US$8 trillion.
            </p>
            <a className={`button button-200 hero-button ${styles.button}`} href={pageLink.store} target="_blank" rel="noreferrer" aria-label="start now">start now</a>
          </div>
        </div>
      </section>
      <section className={styles.sectionFuture}>
        <div className={styles.container}>
          <div className={styles.content}>
            <img className={styles.mobileImg} src={peopleImg} alt="" />
            <h1>Future Proof Your Business</h1>
            <p>More than 100+ million crypto natives are looking for new experiences and as an early adopter, your Metaverse space has an eager and highly engaged target audience.</p>
          </div>
          <div className={styles.imgWrapper}>
            <img src={peopleImg} alt="" />
          </div>
        </div>
      </section>
      <section id="features" className={styles.sectionBanner}>
        <div className={styles.container}>
          <h1>With Double You Can…</h1>
        </div>
      </section>
      <section className={styles.sectionGrid}>
        <div className={styles.container}>
          <div className={styles.grids}>
            <div className={`${styles.island} ${styles.grid}`}>
              <img src={gridIslandImg} alt=""/>
              <div className={styles.captionWrapper}>
                <h2 className={styles.gridCaption}>Promote your social media profile, website or app</h2>
              </div>
            </div>
            <div className={`${styles.gallery} ${styles.grid}`}>
              <img src={gridGalleryImg} alt=""/>
              <div className={styles.captionWrapper}>
                <h2 className={styles.gridCaption}>Highlight your portfolio, skills or services</h2>
              </div>
            </div>
            <div className={`${styles.block} ${styles.grid}`}>
              <img src={gridBlockImg} alt=""/>
              <div className={styles.captionWrapper}>
                <h2 className={styles.gridCaption}>Easy integration</h2>
              </div>
            </div>
            <div className={`${styles.pond} ${styles.grid}`}>
              <img src={gridPondImg} alt=""/>
              <div className={styles.captionWrapper}>
                <h2 className={styles.gridCaption}>Sell your products </h2>
              </div>
            </div>
            <div className={`${styles.street} ${styles.grid}`}>
              <img src={gridStreetImg} alt=""/>
              <div className={styles.captionWrapper}>
                <h2 className={styles.gridCaption}>Stand out from the crowd</h2>
              </div>
            </div>
            <div className={`${styles.portal} ${styles.grid}`}>
              <img src={gridPortalImg} alt=""/>
              <div className={styles.captionWrapper}>
                <h2 className={styles.gridCaption}>Be an early adopter</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="spaces" className={styles.sectionSpaces}>
        <div className={styles.container}>
          <h1 className={styles.title}>Create YOUR space in the Metaverse</h1>
          <SellersSwiper />
        </div>
      </section>
      <CallToAction callBack={() => googleConversion(conversionId.getStarted)} />
    </Layout>      
  )
}

export default sellers