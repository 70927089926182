import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination";
import ReactHtmlParser from 'react-html-parser';

import storeThumbnailImg from '../../assets/images/store_thumbnail.png';
import galleryThumbnailImg from '../../assets/images/gallery_thumbnail.png';
import socialThumbnailImg from '../../assets/images/social_thumbnail.png';
import worldThumbnailImg from '../../assets/images/world_thumbnail.png';
import sellImg from '../../assets/icon/noun-sell.png';
import atomImg from '../../assets/icon/atom.png';
import crowdImg from '../../assets/icon/crowd.png';
import handshakeImg from '../../assets/icon/handshake.png';
import applyImg from '../../assets/icon/noun-apply.png';
import audienceImg from '../../assets/icon/noun-audience.png';
import designImg from '../../assets/icon/noun-design.png';
import readingImg from '../../assets/icon/reading.png';
import scenaryImg from '../../assets/icon/scenary.png';

import * as styles from "./index.module.scss"
import "./index.scss"

const specs = [
  {
    title: "Metaverse Store",
    titleThumbnail: storeThumbnailImg,
    firstIcon: sellImg,
    secondIcon: designImg,
    thirdIcon: audienceImg,
    forthIcon: applyImg,
    firstLine: "<strong>Sell</strong> products and services to 100M+ crypto natives",
    secondLine: "<strong>Design</strong> an immersive, next-gen shopping experience",
    thirdLine: "<strong>Future-proof</strong> your business and reach early adopters",
    forthLine: "<strong>Easy integration</strong> with existing platforms",
  },
  {
    title: "Metaverse Gallery",
    titleThumbnail: galleryThumbnailImg,
    firstIcon: designImg,
    secondIcon: scenaryImg,
    thirdIcon: readingImg,
    forthIcon: sellImg,
    firstLine: "<strong>Create</strong> a virtual gallery in the Metaverse",
    secondLine: "<strong>House and curate</strong> digital art, images, models and NFTs",
    thirdLine: "<strong>Enable</strong> immersive experiences",
    forthLine: "<strong>Sell</strong> to a rapidly growing community of crypto-savvy, power collectors",
  },
  {
    title: "Metaverse Social Media Hub",
    titleThumbnail: socialThumbnailImg,
    firstIcon: crowdImg,
    secondIcon: atomImg,
    thirdIcon: designImg,
    forthIcon: handshakeImg,
    firstLine: "<strong>Become</strong> a leading Metaverse and Web3 influencer",
    secondLine: "<strong>Create</strong> your own virtual world – the equivalent of a social media profile page",
    thirdLine: "<strong>Build</strong> your following and reputation early",
    forthLine: "<strong>Grow</strong> tech endorsements and partnership deals",
  },
  {
    title: "Metaverse World",
    titleThumbnail: worldThumbnailImg,
    firstIcon: designImg,
    secondIcon: audienceImg,
    thirdIcon: readingImg,
    forthIcon: crowdImg,
    firstLine: "<strong>Create</strong> a virtual world today as the first step in your Metaverse strategy",
    secondLine: "<strong>Expand</strong> your audience and impact",
    thirdLine: "<strong>Increase</strong> your distribution on devices like VR and AR headsets",
    forthLine: "<strong>Build, explore and connect</strong> with the Metaverse and Web3 communities",
  },
]

const SellersSwiper = () => {
  return (
    <Swiper
    navigation={true}
    pagination={true}
    modules={[Navigation, Pagination]}
    className="sellerSlide"
    >
      <SwiperSlide>
        <div className={styles.card}>
          <div className={styles.cardTop}>
            <img src={specs[0].titleThumbnail} alt="" />
            <h3>{specs[0].title}</h3>
          </div>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[0].firstIcon} alt=""/>
              </div>  
              <div className={styles.contentText}>{ReactHtmlParser(specs[0].firstLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[0].secondIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[0].secondLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[0].thirdIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[0].thirdLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[0].forthIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[0].forthLine)}</div>
            </div>
          </div>
          <div className={styles.bottomBar}></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={styles.card}>
          <div className={styles.cardTop}>
            <img src={specs[1].titleThumbnail} alt="" />
            <h3>{specs[1].title}</h3>
          </div>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[1].firstIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[1].firstLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[1].secondIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[1].secondLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[1].thirdIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[1].thirdLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[1].forthIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[1].forthLine)}</div>
            </div>
        </div>
          <div className={styles.bottomBar}></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={styles.card}>
          <div className={styles.cardTop}>
            <img src={specs[2].titleThumbnail} alt="" />
            <h3>{specs[2].title}</h3>
          </div>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[2].firstIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[2].firstLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[2].secondIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[2].secondLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[2].thirdIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[2].thirdLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[2].forthIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[2].forthLine)}</div>
            </div>
          </div>
          <div className={styles.bottomBar}></div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={styles.card}>
          <div className={styles.cardTop}>
            <img src={specs[3].titleThumbnail} alt="" />
            <h3>{specs[3].title}</h3>
          </div>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[3].firstIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[3].firstLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[3].secondIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[3].secondLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[3].thirdIcon} alt=""/>  
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[3].thirdLine)}</div>
            </div>
            <div className={styles.contentItem}>
              <div className={styles.imgWrapper}>
                <img src={specs[3].forthIcon} alt=""/>
              </div>
              <div className={styles.contentText}>{ReactHtmlParser(specs[3].forthLine)}</div>
            </div>
          </div>
          <div className={styles.bottomBar}></div>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default SellersSwiper


// // can use it after solve the problem of cannot use map
// const Swipecard = ({props}) => (
//   <SwiperSlide>
//     <div className={styles.card}>
//       <div className={styles.cardTop}>
//         <img src={props.titleThumbnail} alt="" />
//         <h3>{props.title}</h3>
//       </div>
//       <div className={styles.content}>
//         <div className={styles.contentItem}>
//           <img src={props.firstIcon} />
//           <div className={styles.contentText}>{ReactHtmlParser(props.firstLine)}</div>
//         </div>
//         <div className={styles.contentItem}>
//           <img src={props.secondIcon} />
//           <div className={styles.contentText}>{ReactHtmlParser(props.secondLine)}</div>
//         </div>
//         <div className={styles.contentItem}>
//           <img src={props.thirdIcon} />
//           <div className={styles.contentText}>{ReactHtmlParser(props.thirdLine)}</div>
//         </div>
//         <div className={styles.contentItem}>
//           <img src={props.forthIcon} />
//           <div className={styles.contentText}>{ReactHtmlParser(props.forthLine)}</div>
//         </div>
//       </div>
//     </div>
//   </SwiperSlide>
// )